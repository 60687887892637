import Vue from 'vue';
import App from './App.vue';
import { Configuration } from './ClientConfiguration';

export const main = (unvalidatedconfiguration: Configuration): void => {
  try {
    unvalidatedconfiguration.applicationDivId = unvalidatedconfiguration.applicationDivId ? unvalidatedconfiguration.applicationDivId : 'app';
    const vueApp = document.getElementById(unvalidatedconfiguration.applicationDivId);

    if (vueApp) {
      const hesVueAppWrapper = document.createElement('div');

      hesVueAppWrapper.classList.add('hesAPISPA');
      hesVueAppWrapper.appendChild(vueApp.cloneNode(true));
      vueApp.replaceWith(hesVueAppWrapper);
    }

    if (!unvalidatedconfiguration.clientBaseUrl) {
      let baseUrl = '';
      const scripts = document.getElementsByTagName('script');

      for (let i = 0; i < scripts.length; i += 1) {
        if (scripts[i].src.endsWith('assessment-client.umd.js')) {
          baseUrl = scripts[i].src.replace('/assessment-client.umd.js', '');
        }
      }

      if (!baseUrl) {
        throw new Error('client js url cannot be found');
      }

      unvalidatedconfiguration.clientBaseUrl = baseUrl;
    }

    if (![
      'localvue',
      'ci'
    ].includes(unvalidatedconfiguration.apiEnvironment)) {
      const { head } = document;
      const css = document.createElement('link');

      css.setAttribute('rel', 'stylesheet');
      css.setAttribute('type', 'text/css');
      css.setAttribute('media', 'all');
      css.setAttribute('href', `${unvalidatedconfiguration.clientBaseUrl}/assessment-client.css`);
      head.appendChild(css);
    }

    new Vue({
      render: h => h(App, {
        props: {
          unvalidatedconfiguration
        }
      })
    }).$mount(`#${unvalidatedconfiguration.applicationDivId}`);
  } catch (error) {
    if (error instanceof Error) {
      document.write(`<pre>${error.message}</pre>`);
    }

    throw error;
  }
};
