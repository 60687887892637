import { CareerUserRatingData } from '@/use/useCareerProfileDialog';
import { CareerFullRead, CareerUserRatingFullRead, ReportEngine } from '@humanesources.com/api-client';
import { CareerRating, ValidatedConfiguration } from '../ClientConfiguration';
import { CareerRatingEngine } from './career-rating-engine';

export class DefaultCareerRatingEngine extends CareerRatingEngine {
  public async deleteCareerUserRating(careerId: string) {
    await this.reportEngine.deleteCareerUserRating(careerId);
  }

  public async createCareerUserRating(careerId: string, rating: number): Promise<CareerUserRatingData> {
    const careerUserRatingFullRead = await this.reportEngine.createCareerUserRating(careerId, String(rating + 1));

    return this.getCareerUserRatingData(careerUserRatingFullRead);
  }

  public getCareerUserRating(careerId: string): CareerUserRatingData | null {
    const careerUserRatingFullRead = this.reportEngine.getCareerUserRating(careerId);

    if (careerUserRatingFullRead) {
      return this.getCareerUserRatingData(careerUserRatingFullRead);
    }

    return null;
  }

  public getCareerUserRatings(): Map<string, CareerUserRatingData> {
    const
      careerUserRatings = this.reportEngine.getCareerUserRatings(),
      careerUserRatingData = new Map<string, CareerUserRatingData>();

    careerUserRatings.forEach((careerUserRatingFullRead: CareerUserRatingFullRead) => {
      careerUserRatingData.set(careerUserRatingFullRead.career.id, this.getCareerUserRatingData(careerUserRatingFullRead));
    });

    return careerUserRatingData;
  }

  private getCareerUserRatingData(careerUserRatingFullRead: CareerUserRatingFullRead): CareerUserRatingData {
    return {
      careerId: careerUserRatingFullRead.career.id,
      onetId: careerUserRatingFullRead.career.onetId,
      userId: this.reportEngine.getUserId(),
      rating: careerUserRatingFullRead.rating.value
    };
  }
}
