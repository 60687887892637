import { CareerFullRead, ReportEngine } from '@humanesources.com/api-client';
import { CareerRating, ValidatedConfiguration } from '../ClientConfiguration';
import { CareerUserRatingData } from './../use/useCareerProfileDialog';

export abstract class CareerRatingEngine {
    protected reportEngine: ReportEngine;

    public constructor(reportEngine: ReportEngine) {
      this.reportEngine = reportEngine;
    }

    public abstract deleteCareerUserRating(careerId: string): Promise<void>;

    public abstract createCareerUserRating(careerId: string, rating: number): Promise<CareerUserRatingData>;

    public abstract getCareerUserRating(careerId: string): CareerUserRatingData | null;

    public abstract getCareerUserRatings(): Map<string, CareerUserRatingData>;
}
