import { DefaultCareerRatingEngine } from './default-career-rating-engine';
import { AlternativeCareerRatingEngine } from './alternative-career-rating-engine';
import { ReportEngine } from '@humanesources.com/api-client';
import { CareerRating } from '../ClientConfiguration';

export class CareerRatingEngineFactory {
  static async getCareerRatingEngine(reportEngine: ReportEngine, careerRatings?: Array<CareerRating>): Promise<AlternativeCareerRatingEngine | DefaultCareerRatingEngine> {
    if (Array.isArray(careerRatings)) {
      const allCareers = await reportEngine.getAllCareers();

      return new AlternativeCareerRatingEngine(reportEngine, allCareers, careerRatings);
    }

    return new DefaultCareerRatingEngine(reportEngine);
  }
}
