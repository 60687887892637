import en from './en-US.json';
import pt from './pt-BR.json';
import es from './es-ES.json';
import fr from './fr-CA.json';
import zh from './zh-CN.json';
import arEg from './ar-EG.json';
import arSa from './ar-SA.json';
import enAu from './en-AU.json';


export const messages = {
  'en-US': en,
  'pt-BR': pt,
  'es-ES': es,
  'fr-CA': fr,
  'zh-CN': zh,
  'ar-EG': arEg,
  'ar-SA': arSa,
  'en-AU': enAu,
};
