import '@fortawesome/fontawesome-free/css/all.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md' || 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: '#3278BD',
        success: '#5f9e43',
        error: '#cc453b',
      },
    },
  },
});
