
import 'core-js/stable';
import 'regenerator-runtime';
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n';
import Vue from 'vue';
import VueCompositionApi, { onErrorCaptured, getCurrentInstance, onBeforeMount, Ref, ref, defineComponent, watch } from '@vue/composition-api';
import * as ApiClient from '@humanesources.com/api-client';
import { Configuration, ValidatedConfiguration } from '@/ClientConfiguration';
import { deprecatedConfigurationIds } from '@/ConfigurationValidation';
import { useAssessmentInit, useConfigurationInit } from '@/use/useAssessmentInit';
import * as i18n from './i18n';
import * as languages from './i18n/Languages';
import { AssessmentEngineLogicError } from '@/errors/assessment-engine-logic-error';
import { DeprecatedConfigurationStartError } from '@/errors/deprecated-configuration-start-error';
import { logError } from './use/useSharedScripts';
import { TranslationInitializationError } from './errors/translation-initialization-error';
import { IncompleteAssessmentReadOnlyError } from './errors/incomplete-assessment-readonly-error';
import { ReportProps } from './ReportProps';

require('dotenv').config();

Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(VueCompositionApi);
Vue.directive('focus', {
  inserted: (el, binding) => {
    if (binding.value) {
      el.focus();
    }
  },
  update: (el, binding) => {
    if (binding.value) {
      Vue.nextTick(() => {
        el.focus();
      });
    }
  }
});

export default defineComponent({
  name: 'AssessmentClient',
  vuetify,
  i18n: new VueI18n({
    locale: 'en-US',
    fallbackLocale: 'en-US',
    messages: i18n.messages
  }),
  props: {
    unvalidatedconfiguration: {
      type: Object as () => Configuration,
      required: true
    }
  },
  setup(props) {
    const
      { unvalidatedconfiguration } = props,
      assessmentEngine: Ref<ApiClient.AssessmentEngine | ApiClient.PersonalityAssessmentEngine | null> = ref(null),
      reportEngine: Ref<ApiClient.ReportEngine | null> = ref(null),
      reportProps: Ref<ReportProps| null> = ref(null),
      configuration: Ref<ValidatedConfiguration> = ref({}) as Ref<ValidatedConfiguration>,
      page = ref('Loading'),
      nextPage: Ref<string|null> = ref(null),
      message = ref(''),
      isReportBlocked = ref(false),
      component = ref(() => import(`./components/${page.value}.vue`)),
      zero = 0,
      renderCount = ref(zero),
      vm = getCurrentInstance(),
      productSuiteClass: Ref<string | undefined> = ref(''),
      isError = ref(false),
      errorMessage = ref(''),
      errorType = ref(''),
      resetError = (): void => {
        isError.value = false;
        errorMessage.value = '';
        errorType.value = '';
      },

      handleError = (error: unknown): void => {
        if (error instanceof Error) {
          logError(error, unvalidatedconfiguration);

          message.value = error.message;

          if (error instanceof ApiClient.ReportBlockedError && vm !== null) {
            message.value = `${vm.$i18n.t('Your report is currently locked. In order to view your report, you will need to contact the person who instructed you to do the assessment, or another person at your site who can unlock the report. Reviewing the report with a qualified individual, who can answer questions and explain things clearly, will provide the most benefit to you.')}`;
            isReportBlocked.value = true;
          } else if (error instanceof ApiClient.ServerError && error.httpStatusCode === 401 && vm !== null) {
            message.value = `${vm.$i18n.t('Please reload the page and try again')}`;
          } else if (error instanceof IncompleteAssessmentReadOnlyError && vm !== null) {
            message.value = `${vm.$i18n.t('Assessment incomplete. You cannot view an incomplete assessment.')}`;
          }

          nextPage.value = 'Error';
        }

        throw error;
      },
      setLocale = (locale: string): void => {
        if (vm && locale) {
          if (vm.$i18n.locale !== locale) {
            const language = languages.getLanguage(locale);

            vm.$i18n.locale = locale;
            vm.$vuetify.rtl = language.rtl;
          }

        } else {
          handleError(new Error('vm or locale does not exist'));
        }
      },
      setReportProperties = async (engine: ApiClient.ReportEngine | ApiClient.AssessmentEngine | ApiClient.PersonalityAssessmentEngine) => {
        if (engine instanceof ApiClient.ReportEngine) {
          reportEngine.value = engine;
        } else {
          reportEngine.value = await engine.getReportEngine(configuration.value.enableReportBlocking);
        }

        reportProps.value = await ReportProps.getReportProps(reportEngine.value.getReport().elements[0], reportEngine.value, configuration.value);
        productSuiteClass.value = reportEngine.value.getProductConfiguration().productSuite;
      },
      reloadPage = async () => {
        let locale = assessmentEngine.value === null ? configuration.value.locale as string : assessmentEngine.value.getLocale();

        if (page.value === 'Report' && reportEngine.value === null) {
          if (assessmentEngine.value === null) {
            handleError(new Error('The assessment engine did not initialize'));
          } else {
            try {
              await setReportProperties(assessmentEngine.value);
            } catch (error) {
              handleError(error);
            }
          }
        }

        locale = reportEngine.value === null ? locale : reportEngine.value.getLocale();
        setLocale(locale);
        renderCount.value += 1;
      },
      displayCurrentState = async (fn: string): Promise<void> => {
        if (fn === 'reloadPage') {
          reloadPage();

          return;
        }

        if (assessmentEngine.value !== null) {
          switch (assessmentEngine.value.getStatus()) {
          case ApiClient.AssessmentEngine.STATE_ANSWER_QUESTIONS: {
            nextPage.value = 'Question';
            break;
          }
          case ApiClient.PersonalityAssessmentEngine.STATE_RATE_CAREER_CLUSTERS: {
            nextPage.value = 'RateCareerClusters';
            break;
          }
          case ApiClient.AssessmentEngine.STATE_CALCULATE_ASSESSMENT: {
            if (assessmentEngine.value !== null) {
              await assessmentEngine.value.calculateAssessment();
            }

            if (assessmentEngine.value instanceof ApiClient.PersonalityAssessmentEngine) {
              nextPage.value = null;
              await displayCurrentState('displayCurrentState');
            } else {
              nextPage.value = 'Loading';
            }

            break;
          }
          case ApiClient.PersonalityAssessmentEngine.STATE_TIE_RESOLVEMENT: {
            nextPage.value = 'Question';
            break;
          }
          case ApiClient.PersonalityAssessmentEngine.STATE_RATE_ASSESSMENT: {
            nextPage.value = 'RateAssessment';
            break;
          }
          case ApiClient.AssessmentEngine.STATE_REPORT: {
            nextPage.value = 'Loading';
            break;
          }
          default: {
            throw new AssessmentEngineLogicError('Invalid state');
          }
          }
        }
      },
      setCSSCustomProperties = () => {
        if (configuration.value.customColors) {
          const root: HTMLDivElement | null = document.getElementsByClassName('hesAPISPA')[0] as HTMLDivElement;

          if (root) {
            for (const [
              key,
              value
            ] of Object.entries(configuration.value.customColors)) {
              root.style.setProperty(`--hes-color-${key}`, value);
            }
          }
        }
      };

    if (unvalidatedconfiguration.locale) {
      setLocale(unvalidatedconfiguration.locale);
    }

    onErrorCaptured((error: Error): boolean | void => {
      isError.value = true;
      errorMessage.value = error.message;
      errorType.value = error.name;
      logError(error, unvalidatedconfiguration);

      return false;
    });

    onBeforeMount(async () => {
      try {
        if (!unvalidatedconfiguration.assessmentId && deprecatedConfigurationIds.includes(unvalidatedconfiguration.configurationId)) {
          if (vm) {
            throw new DeprecatedConfigurationStartError(`${vm.$i18n.t('This version of Skills cannot be started.  Please use the new configuration.')}`);
          } else {
            throw new TranslationInitializationError();
          }
        }

        // eslint-disable-next-line require-atomic-updates
        configuration.value = await useConfigurationInit(unvalidatedconfiguration);

        const
          engine = await useAssessmentInit(configuration.value),
          thisWindow = window as any;

        if (thisWindow.Cypress) {
          thisWindow.assessmentId = engine.getAssessmentId();
          thisWindow.userId = engine.getUser().id;
        }

        setLocale(configuration.value.locale as string);

        if (engine instanceof ApiClient.ReportEngine) {
          await setReportProperties(engine);
          nextPage.value = 'Loading';
        } else {
          // instructors cannot view incomplete assessments
          if (configuration.value.readOnly === true) {
            throw new IncompleteAssessmentReadOnlyError();
          }

          assessmentEngine.value = engine;
          if (configuration.value.onAssessmentStart && !configuration.value.assessmentId) {
            configuration.value.onAssessmentStart(assessmentEngine.value.getAssessment());
          }

          nextPage.value = 'IntroText';
          productSuiteClass.value = assessmentEngine.value.getProductConfiguration().productSuite;
        }
        setCSSCustomProperties();
      } catch (error) {
        handleError(error);
      }
    });

    watch(
      nextPage, async() => {
        try {
          if (nextPage.value) {
            if (nextPage.value === 'Loading') {
              page.value = nextPage.value;

              // If report is accessed after completed assessment
              if (assessmentEngine.value !== null) {
                // eslint-disable-next-line max-depth
                if (configuration.value.onAssessmentComplete) {
                  configuration.value.onAssessmentComplete(assessmentEngine.value.getAssessment());
                }
                page.value = 'Report';
                await setReportProperties(assessmentEngine.value);
                component.value = () => import(`./components/${page.value}.vue`);
              // If report is accessed directly
              } else if (reportEngine.value) {
                page.value = 'Report';
                component.value = () => import(`./components/${page.value}.vue`);
              }
            } else {
              page.value = nextPage.value;
              component.value = () => import(`./components/${page.value}.vue`);
            }
          }
        } catch (error) {
          handleError(error);
        }
      },
      {
        flush: 'sync'
      }
    );

    return {
      configuration,
      page,
      message,
      isReportBlocked,
      renderCount,
      component,
      displayCurrentState,
      assessmentEngine,
      reportEngine,
      reportProps,
      productSuiteClass,
      resetError,
      isError,
      errorMessage,
      errorType
    };
  },
});
