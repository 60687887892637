import { ClientError } from '@/errors/client-error';

export class TranslationInitializationError extends ClientError {
  protected defaultMessage = 'translation function error vm is not available';

  constructor(message?: string) {
    super(message);

    this.name = 'TranslationInitializationError';
  }
}
