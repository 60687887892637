/* eslint-disable no-undefined */
import { datadogRum } from '@datadog/browser-rum';
import Vue from 'vue';
import type { Configuration } from './ClientConfiguration';

export const initializeDataDog = (configuration: Configuration): void => {
  const {
    VUE_APP_DATADOG_ENABLED: enabled,
    VUE_APP_VERSION: version,
    VUE_APP_DATADOG_ENABLE_SESSION_REPLAY: enableSessionReplay,
  } = process.env;

  let {
    VUE_APP_DATADOG_APPLICATION_ID: applicationId,
    VUE_APP_DATADOG_CLIENT_TOKEN: clientToken,
    VUE_APP_DATADOG_SITE: dataDogSite,
  } = process.env;

  if (configuration.apiEnvironment === 'eu_production') {
    applicationId = process.env.VUE_APP_DATADOG_EU_APPLICATION_ID;
    clientToken = process.env.VUE_APP_DATADOG_EU_CLIENT_TOKEN;
    dataDogSite = process.env.VUE_APP_DATADOG_EU_SITE;
  }

  if (enabled === undefined || !JSON.parse(enabled) ||
        version === undefined || applicationId === undefined || clientToken === undefined ||
        !configuration.enableDataDog
  ) {
    return;
  }

  datadogRum.init({
    applicationId,
    clientToken,
    site: dataDogSite,
    service: 'hes-api-client-vue',
    env: configuration.apiEnvironment,
    version,
    sampleRate: 100,
    trackInteractions: true,
    useCrossSiteSessionCookie: true,
    useSecureSessionCookie: true,
  });

  if (enableSessionReplay !== undefined && JSON.parse(enableSessionReplay)) {
    datadogRum.startSessionReplayRecording();
  }

  datadogRum.setUser({
    id: configuration.userId,
  });

  // Redact sensitive configuration values like the API token before adding it to the DD global context
  const { apiToken, ...redactedConfiguration } = configuration;

  datadogRum.addRumGlobalContext('configuration', redactedConfiguration);

  Vue.config.errorHandler = (err, vm, info) => {
    datadogRum.addError(err, {});
  };
};
