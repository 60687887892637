/* eslint-disable require-atomic-updates */
import { initializeDataDog } from '@/DataDog';
import { ConfigurationValidationError } from '@/errors/configuration-validation-error';
import * as ApiClient from '@humanesources.com/api-client';
import { Configuration, ValidatedConfiguration, setupConfiguration } from '../ClientConfiguration';
import { testEnvironments, environmentMap } from '../ConfigurationValidation';

require('dotenv').config();

export const useAssessmentInit = (configuration: ValidatedConfiguration): Promise<ApiClient.AssessmentEngine | ApiClient.PersonalityAssessmentEngine | ApiClient.ReportEngine> => {
  initializeDataDog(configuration);

  const apiHost = environmentMap.get(configuration.apiEnvironment);

  if (apiHost) {
    return ApiClient.AssessmentEngineFactory.getAssessmentEngine(
      apiHost,
      configuration.userId,
      configuration.apiToken,
      configuration.configurationId,
      configuration.displayCareers,
      configuration.displayBadging,
      configuration.enableReportBlocking && !configuration.readOnly,
      configuration.assessmentId,
      configuration.locale
    );
  }

  throw new ConfigurationValidationError('no apiHost value found');
};

export const useConfigurationInit = async (unvalidatedconfiguration: Configuration): Promise<ValidatedConfiguration> => {
  // Only used for local hes developer vue server testing
  if (testEnvironments.includes(unvalidatedconfiguration.apiEnvironment)) {

    //override apiHost value with env value for testing only
    unvalidatedconfiguration.apiEnvironment = process.env.VUE_APP_TEST_ENVIRONMENT as ValidatedConfiguration['apiEnvironment'];

    const apiHost = environmentMap.get(unvalidatedconfiguration.apiEnvironment);

    if (!apiHost) {
      throw new ConfigurationValidationError('invalid environment');
    }

    const setup = new ApiClient.Setup(apiHost),
      user = await setup.createUser(),
      // If configuration.userId is set, existing user and assessment are being used
      userId = unvalidatedconfiguration.userId ? unvalidatedconfiguration.userId : user.id,
      assessmentClient = await setup.getAssessmentClient(userId);

    unvalidatedconfiguration.userId = userId;
    unvalidatedconfiguration.apiToken = assessmentClient.getToken();
  }

  return setupConfiguration(unvalidatedconfiguration);
};
