export class ClientError extends Error {
  protected defaultMessage = ''

  constructor(message?: string) {
    super('');

    this.name = 'HesAssessmentClientError';
    this.message = message ? message : this.defaultMessage;
  }
}
